import React from 'react';
import classNames from 'classnames/bind';
import { withHMR } from '@beef/react';
import { compose } from '@beef/utils';

import { initializeStore } from 'store';
import { rootReducer } from 'store/reducers';
import { withDimension, withReduxStore, withSmartReact } from 'hocs';
import { Suspense } from 'shared/Suspense';

import Routes from '../routes';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const App = () => (
  <div name="app" className={cx('app')}>
    <React.Suspense fallback={<Suspense />}>
      <div className={cx('page-container')}>
        <Routes />
      </div>
    </React.Suspense>
  </div>
);

export default compose(
  withHMR(module),
  withReduxStore(initializeStore(rootReducer, { name: 'VKIT' })),
  withDimension.Provider,
  withSmartReact({ noSSR: true }),
)(App);
