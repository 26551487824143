import { createReducer } from '@reduxjs/toolkit';

import model from './content.json';

const initialState = {
  isLoading: false,
  error: '',
  content: model,
};

export const mainReducer = createReducer(initialState, {});
