import React, { useRef, useEffect, FC } from 'react';

import { SmartReactContext } from './context';
import { withSmartReactType } from './types';

export function withSmartReact<T extends withSmartReactType>({ noSSR = true }) {
  return (WrappedComponent: FC<T>) => {
    if (noSSR && typeof window === 'undefined') {
      throw new Error('Wrong configuration');
    }

    return (props: T) => {
      const isHydration = useRef(true);

      useEffect(() => {
        isHydration.current = false;
      }, []);

      return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <SmartReactContext.Provider value={{ isHydration }}>
          <WrappedComponent {...props} />
        </SmartReactContext.Provider>
      );
    };
  };
}
