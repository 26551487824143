import React from 'react';
import ReactDOM from 'react-dom';
// import smoothScroll from 'smoothscroll-polyfill';

import App from './containers/App';
import './index.css';
import './styles/common.pcss';

if (typeof window === 'object') {
  ReactDOM.render(<App />, document.getElementById('root'));
}
