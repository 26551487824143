import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import {
  ERROR_ROUTE, MAIN_PAGE_ROUTE,
} from 'constants/routes';
import { ErrorPage, MainPage } from 'pages';

export default () => (
  <BrowserRouter>
    <Routes>
      <Route path={ERROR_ROUTE} element={<ErrorPage />} />
      <Route path={MAIN_PAGE_ROUTE} element={<MainPage />} />

      <Route path="*" element={<Navigate to={ERROR_ROUTE} />} />
    </Routes>
  </BrowserRouter>
);
