import React from 'react';
import { Provider } from 'react-redux';

/**
 * HOC for connecting SinglePage redux store
 * WrappedComponent is not supposed to remount and will erase shop data on each new mount
 * */
export function withReduxStore(store) {
  return (WrappedComponent) => (
    (props) => (
      <Provider store={store}>
        <WrappedComponent {...props} />
      </Provider>
    )
  );
}
