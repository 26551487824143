import { configureStore } from '@reduxjs/toolkit';

/**
 * Initialize Store Function
 * @param {object | function} reducer
 * @param {object | boolean} devToolsOptions
 * @returns {EnhancedStore<any, AnyAction, [ThunkMiddlewareFor<any>]>}
 */
export const initializeStore = (reducer, devToolsOptions = true) => configureStore({
  reducer,
  devTools: devToolsOptions,
});
