import React, { FC, useMemo } from 'react';
import { useIsMobileDimension,
  useIsTabletDimension, useIsDesktopDimension } from '@beef/ui-kit/hooks';

import { DimensionContext } from 'context';

function Provider<TProps>(WrappedComponent: FC<TProps>) {
  const Component = (props: TProps) => {
    const isMobile = useIsMobileDimension();
    const isTablet = useIsTabletDimension();
    const isDesktop = useIsDesktopDimension();

    const dimensions = useMemo(() => ({ isMobile, isTablet, isDesktop }), [isDesktop, isMobile, isTablet]);

    return (
      <DimensionContext.Provider value={dimensions}>
        <WrappedComponent {...props} />
      </DimensionContext.Provider>
    );
  };

  /**
   * Override component name by prepending `~WithDimensionProvider`
   * to make it look nice, for example: `ComponentWithDimensionProvider`
   */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    Component.displayName = `${WrappedComponentName}WithDimensionProvider`;
  }

  return Component;
}

function Consumer<TProps>(WrappedComponent: FC<TProps>) {
  const Component = (props: TProps) => (
    <DimensionContext.Consumer>
      {(dimensions) => <WrappedComponent {...props} {...dimensions} />}
    </DimensionContext.Consumer>
  );

  /**
   * Override component name by prepending `~WithDimension`
   * to make it look nice, for example: `ComponentWithDimension`
   */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    Component.displayName = `${WrappedComponentName}WithDimension`;
  }

  return Component;
}

export const withDimension = {
  Consumer,
  Provider,
};
