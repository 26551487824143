import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Preloader } from '@beef/ui-kit';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Suspense: FC = () => (
  <div className={cx('component')}>
    <div className={cx('preloader')}>
      <Preloader />
    </div>
  </div>
);
